// Components
import { U21Spacer } from 'app/shared/u21-ui/components';
import { useSelector } from 'react-redux';
import { FlaggedTransactionsTable } from 'app/modules/alerts/components/AlertDetails/FlaggedTransactionsTable';
import { AlertEntityTransactionsTable } from 'app/modules/alerts/components/AlertDetails/AlertEntityTransactionsTable';
import { ActivityHistogram } from 'app/modules/alerts/components/AlertDetails/TransactionActivity/ActivityHistogram';
import { TransactionActivityFilters } from 'app/modules/alerts/components/AlertDetails/TransactionActivity/TransactionActivityFilters';
import { selectHasReadEventsPermission } from 'app/modules/session/selectors';
import { selectShowCurrentAlertActivityFilter } from 'app/modules/alerts/sliceSelectors';

export const TransactionActivity = () => {
  const showCurrentAlert = useSelector(selectShowCurrentAlertActivityFilter);
  const hasReadEventsPermission = useSelector(selectHasReadEventsPermission);

  return (
    <U21Spacer spacing={1}>
      {hasReadEventsPermission && <TransactionActivityFilters />}
      {showCurrentAlert ? (
        <FlaggedTransactionsTable />
      ) : (
        <AlertEntityTransactionsTable />
      )}
      <ActivityHistogram />
    </U21Spacer>
  );
};
