import { selectAlertEntityOptions } from 'app/modules/alerts/selectors';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

// Components
import { U21Select } from 'app/shared/u21-ui/components';

// Models
import { setSelectedEntityActivityFilter } from 'app/modules/alerts/sliceAlerts';
import {
  selectSelectedEntityActivityFilter,
  selectShowCurrentAlertActivityFilter,
} from 'app/modules/alerts/sliceSelectors';

export const EntitySelect = () => {
  const options = useSelector(selectAlertEntityOptions);
  const dispatch = useDispatch();

  const showCurrentAlert = useSelector(selectShowCurrentAlertActivityFilter);
  const selectedEntity = useSelector(selectSelectedEntityActivityFilter);

  // If there are no entities associated with the given alert, hide the entity dropdown
  if (options.length === 0 || showCurrentAlert) {
    return null;
  }

  return (
    <StyledU21Select
      clearable={false}
      searchable={false}
      options={options}
      value={selectedEntity}
      onChange={(newSelectedEntity: string) => {
        dispatch(setSelectedEntityActivityFilter(newSelectedEntity));
      }}
      label="Entity"
    />
  );
};

const StyledU21Select = styled(U21Select)`
  width: 250px;
`;
