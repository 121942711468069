// Types
import {
  FullCaseResponse,
  ShortCaseResponse,
} from 'app/modules/casesOld/types';
import { FullTagResponse } from 'app/modules/tags/responses';
import { U21SelectOptionProps } from 'app/shared/u21-ui/components';
import { InvestigationsAgentStatsResponse } from 'app/modules/investigations/types';
import { CustomDataFilters } from 'app/modules/filtersOld/models';
import { GoogleDriveDoc } from 'app/modules/uploads/models';
import { Filter } from 'app/modules/filters/models';
import { PaginationPayload } from 'app/shared/pagination/models';

export interface NewCaseSummary extends ShortCaseResponse {}
export interface NewCaseDetails extends FullCaseResponse {}

// Used in sliceCases
export interface NewCasesState {
  adminCaseFilters: Filter[];
  myCaseFilters: Filter[];
  queuedCaseFilters: Filter[];
  cases: NewCaseSummary[];
  agentStats: InvestigationsAgentStatsResponse;
  loadingRetrieveAgentStats: boolean;
  loadingRetrieveCases: boolean;
  count: number;
  loadingCreateCase: boolean;
  loadingEditCase: boolean;
}

// todo: move to types/requests
export interface CaseFiltersPayload {
  my_queues_only?: boolean;
  assigned_to_id?: number[];
  case_ids?: number[];
  created_by_id?: number;
  created_end_date?: string;
  created_start_date?: string;
  disposition?: string;
  disposition_end_date?: string;
  disposition_start_date?: string;
  entity_ids?: (number | string)[];
  maximum_amount?: number;
  minimum_amount?: number;
  sources?: string[];
  statuses?: string[];
  subdisposition_ids?: number[];
  subdisposition_option_ids?: number[];
  is_not_subdisposition_option_ids?: number[];
  tag_ids?: number[];
  is_not_tag_ids?: number[];
  team_ids?: number[];
  watcher_ids?: number[];
  phrase?: string;
  queue_ids?: number[];
  custom_data_filters?: CustomDataFilters;
  entity_external_ids?: string[];
  instrument_external_ids?: string[];
}

export type GetCasesPayload = CaseFiltersPayload & PaginationPayload;

export interface CreateCasePayload {
  form: {
    title: string;
    description: string;
    startDate: string;
    endDate: string;
  };
  watchers: number[];
  tags: number[];
  alerts: number[];
  entities: (number | string)[];
  events: string[];
  action_events: string[];
  assigned_to_id?: number;
  queue_id: number;
  add_related_entities: boolean;
  add_related_txn_events: boolean;
  add_related_instruments: boolean;
}

export interface CasesAddAlertParams {
  events: U21SelectOptionProps[];
  entities: U21SelectOptionProps[];
  action_events: U21SelectOptionProps[];
  instruments: U21SelectOptionProps[];
}

export interface CreateCaseLocationState {
  originating_alert_id?: number;
  alerts?: {
    id: number;
    title: string;
    description: string;
    tags?: FullTagResponse[];
  }[];
  assignee?: number;

  // separate out entities + events into separate fields instead of using alerts
  // because there are different formats for the alert entities + events so
  // the caller should be the one that deals with the formats
  entities?: (number | string)[];
  events?: string[];
  actionEvents?: string[];
  queue?: number;
  instruments?: (number | string)[];
}

export enum CasePages {
  ADMIN_CASES = 'ADMIN_CASES',
  MY_CASES = 'MY_CASES',
  QUEUED_CASES = 'QUEUED_CASES',
  QUEUE_DETAILS = 'QUEUE_DETAILS',
  QUALITY_CYCLE_CASES = 'QUALITY_CYCLE_CASES',
}

export interface CreateCaseFormValues {
  title: string;
  description: string;
  assignee?: number;
  queue: number;
  start_date: string;
  end_date?: string;
  tags?: number[];
  watchers?: number[];
  alerts?: number[];
  entities?: (number | string)[];
  events?: string[];
  action_events?: string[];
  instruments?: (number | string)[];
  add_related_entities: boolean;
  add_related_txn_events: boolean;
  add_related_instruments: boolean;
  s3Docs?: File[];
  gdriveDocs?: File[];
  gdrivePickedDocs?: GoogleDriveDoc[];
}

export interface CaseDetailsFormValues {
  tags?: number[];
  watchers?: number[];
  alerts?: number[];
  entities?: string[];
  events?: number[];
  action_events?: number[];
  instruments?: string[];
}

export enum CaseDetailsPages {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}
