// Components
import { U21Spacer } from 'app/shared/u21-ui/components';
import { useSelector } from 'react-redux';
import { ChainalysisAlertDetails } from 'app/modules/alerts/components/AlertDetails/ChainalysisSummary/ChainalysisAlertDetails';
import { FlaggedTransactionsTable } from 'app/modules/alerts/components/AlertDetails/FlaggedTransactionsTable';
import { AlertEntityTransactionsTable } from 'app/modules/alerts/components/AlertDetails/AlertEntityTransactionsTable';
import { selectShowCurrentAlertActivityFilter } from 'app/modules/alerts/sliceSelectors';

export const ChainalysisSummary = () => {
  const showCurrentAlert = useSelector(selectShowCurrentAlertActivityFilter);
  return (
    <U21Spacer spacing={1}>
      <ChainalysisAlertDetails />
      {showCurrentAlert ? (
        <FlaggedTransactionsTable />
      ) : (
        <AlertEntityTransactionsTable />
      )}
    </U21Spacer>
  );
};
