import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Models
import { TableConfigType } from 'app/shared/CustomConfig/models';
import {
  ActionEventSummary,
  RetrieveActionEventsPayload,
} from 'app/shared/events/models';

// Components
import { IconFlagX } from 'app/shared/components/Icons/IconFlagX';
import { IconFlag, IconLayoutColumns } from '@u21/tabler-icons';
import {
  U21Button,
  U21Section,
  U21Table,
  U21TableState,
} from 'app/shared/u21-ui/components';

// Selectors
import {
  selectActionEventsCount,
  selectActionEventsData,
  selectActionEventsLoading,
} from 'app/shared/events/selectors';
import { selectActionEventTableConfig } from 'app/shared/CustomConfig/selectors';
import {
  selectAlertActionEvents,
  selectAlertEntities,
  selectAlertLoading,
  selectAlert,
  selectAlertActionEventsLoading,
} from 'app/modules/alerts/selectors';

// Actions
import { retrieveActionEvents } from 'app/shared/events/actions';
import { toggleTableConfigSidebar } from 'app/modules/sidebar/slice';
import { retrieveAlertActionEvents } from 'app/modules/alerts/actions';

// Utils
import {
  createPaginationPayload,
  createTableColumnConfig,
} from 'app/shared/utils/table';
import { ACTION_EVENTS_COLUMN_CONFIG } from 'app/modules/actionEvents/columns';
import { selectHasReadEventsPermission } from 'app/modules/session/selectors';
import { useGetActionEvents } from 'app/modules/actionEvents/queries/useGetActionEvents';
import { selectDLEnabledForAlertActionEvents } from 'app/shared/featureFlags/selectors';
import { DEFAULT_PAGINATION_PAYLOAD } from 'app/shared/pagination/constants';
import { selectSidebarActionEventID } from 'app/modules/sidebar/selectors';
import { useToggleActionEventSidebar } from 'app/modules/sidebar/hooks';
import { selectActivityFilters } from 'app/modules/alerts/sliceSelectors';

interface ActionTableFilters {
  entity_ids: (number | string)[];
  alert_id: number;
}

export const EventActivityTable = () => {
  const dispatch = useDispatch();

  const entities = useSelector(selectAlertEntities);
  const { showCurrentAlert, selectedEntity } = useSelector(
    selectActivityFilters,
  );
  const currentEntity = useMemo(
    () => entities.find(({ id }) => id === selectedEntity),
    [selectedEntity, entities],
  );

  const legacyEntityActionEvents = useSelector(selectActionEventsData);
  const legacyFlaggedEvents = useSelector(selectAlertActionEvents);
  const entityEventsLoading = useSelector(selectActionEventsLoading);
  const legacyFlaggedEventsLoading = useSelector(
    selectAlertActionEventsLoading,
  );
  const alertLoading = useSelector(selectAlertLoading);
  const tableConfig = useSelector(selectActionEventTableConfig);
  const legacyEntityEventsCount = useSelector(selectActionEventsCount);
  const alert = useSelector(selectAlert);
  const hasReadEventsPermission = useSelector(selectHasReadEventsPermission);
  const hasDLEnabledForAlertActionEvents = useSelector(
    selectDLEnabledForAlertActionEvents,
  );
  const sidebarActionEventID = useSelector(selectSidebarActionEventID);

  const toggleActionEventSidebar = useToggleActionEventSidebar();

  const [entityActionEventsPayload, setEntityActionEventsPayload] =
    useState<RetrieveActionEventsPayload>({
      ...DEFAULT_PAGINATION_PAYLOAD,
      // First call should be once the table is mounted and the payload is populated with the sorting and filtering
      limit: 0,
    });
  const [alertActionEventsPayload, setAlertActionEventsPayload] = useState<
    Omit<RetrieveActionEventsPayload, 'alert_id'> & { alert_id: number }
  >({
    ...DEFAULT_PAGINATION_PAYLOAD,
    alert_id: alert.id,
    // First call should be once the table is mounted and the payload is populated with the sorting and filtering
    limit: 0,
  });

  const filters: ActionTableFilters = useMemo(
    () => ({
      entity_ids: currentEntity ? [currentEntity.id] : [],
      alert_id: alert.id,
    }),
    [currentEntity, alert.id],
  );

  const newColumns = useMemo(
    () =>
      createTableColumnConfig<ActionEventSummary>(
        tableConfig,
        ACTION_EVENTS_COLUMN_CONFIG,
      ),
    [tableConfig],
  );

  // We have 2 different requests, one holds the results of the flagged action events, the second one tracks the action events of the selected entity
  // They could be merged but this would makes the logic too convoluted and harder to modify. react-query caches results so performance should be the same
  const { data: flaggedActionEvents, isLoading: flaggedActionEventsLoading } =
    useGetActionEvents(alertActionEventsPayload, {
      useCacheEndpoint: true,
      enabled: hasDLEnabledForAlertActionEvents && showCurrentAlert,
    });

  const { data: entityActionEvents, isLoading: entityActionEventsLoading } =
    useGetActionEvents(entityActionEventsPayload, {
      useCacheEndpoint: true,
      enabled: hasDLEnabledForAlertActionEvents && !showCurrentAlert,
    });

  // Effects to trigger the legacy requests when the payload changes AND we are not using DL endpoints
  useEffect(() => {
    if (
      !hasDLEnabledForAlertActionEvents &&
      entityActionEventsPayload.limit > 0
    ) {
      dispatch(retrieveActionEvents(entityActionEventsPayload));
    }
  }, [hasDLEnabledForAlertActionEvents, entityActionEventsPayload, dispatch]);

  useEffect(() => {
    if (
      !hasDLEnabledForAlertActionEvents &&
      alertActionEventsPayload.limit > 0
    ) {
      dispatch(retrieveAlertActionEvents(alertActionEventsPayload));
    }
  }, [hasDLEnabledForAlertActionEvents, alertActionEventsPayload, dispatch]);

  const { rows, count, loading } = useMemo<{
    rows: ActionEventSummary[];
    count: number | null;
    loading: boolean;
  }>(() => {
    let mRows: ActionEventSummary[] = [];
    let mCount: number | null = null;
    let mLoading: boolean = alertLoading;

    if (hasDLEnabledForAlertActionEvents && showCurrentAlert) {
      mRows = flaggedActionEvents?.action_events || [];
      mCount = flaggedActionEvents?.count ?? null;
      mLoading = mLoading || flaggedActionEventsLoading;
    } else if (hasDLEnabledForAlertActionEvents && !showCurrentAlert) {
      mRows = entityActionEvents?.action_events || [];
      mCount = entityActionEvents?.count ?? null;
      mLoading = mLoading || entityActionEventsLoading;
    } else if (showCurrentAlert) {
      // No DL meaning Legacy logic and we are showing ALERT events
      mRows = legacyFlaggedEvents.events;
      mCount = legacyFlaggedEvents.count;
      mLoading = mLoading || legacyFlaggedEventsLoading;
    } else {
      // No DL meaning Legacy logic and we are showing ENTITY events
      mRows = legacyEntityActionEvents;
      mCount = legacyEntityEventsCount;
      mLoading = mLoading || entityEventsLoading;
    }
    return {
      rows: mRows,
      count: mCount,
      loading: mLoading,
    };
  }, [
    hasDLEnabledForAlertActionEvents,
    flaggedActionEvents,
    flaggedActionEventsLoading,
    showCurrentAlert,
    legacyFlaggedEvents,
    legacyEntityActionEvents,
    legacyEntityEventsCount,
    entityEventsLoading,
    alertLoading,
    legacyFlaggedEventsLoading,
    entityActionEvents,
    entityActionEventsLoading,
  ]);

  const highlighted = useMemo(
    () => (sidebarActionEventID ? [sidebarActionEventID] : undefined),
    [sidebarActionEventID],
  );

  return (
    <U21Section
      titleIcon={showCurrentAlert ? <IconFlag /> : <IconFlagX />}
      action={
        <U21Button
          onClick={() => {
            dispatch(
              toggleTableConfigSidebar({
                tableConfigType: TableConfigType.ACTION_EVENT_TABLE,
              }),
            );
          }}
          startIcon={<IconLayoutColumns />}
        >
          Choose Columns
        </U21Button>
      }
      title={
        !showCurrentAlert && currentEntity?.name_readable
          ? `${currentEntity?.name_readable}'s Events`
          : `Flagged Events`
      }
    >
      <U21Table
        columns={newColumns}
        count={count}
        data={rows}
        defaultSortBy={[{ desc: true, id: 'id' }]}
        highlighted={highlighted}
        loading={Boolean(loading)}
        filters={filters}
        manualPagination
        onRowClick={
          hasReadEventsPermission
            ? (_, actionEvent: ActionEventSummary, e) => {
                toggleActionEventSidebar(actionEvent.id, e);
              }
            : undefined
        }
        onStateChange={(
          state: U21TableState,
          { alert_id: alertID, ...tableFilters }: ActionTableFilters,
        ) => {
          if (showCurrentAlert) {
            if (alertID < 0) {
              return;
            }
            setAlertActionEventsPayload({
              ...createPaginationPayload(state),
              alert_id: alertID,
            });
          } else {
            // only retrieve actions if entities are associated with the alert
            if (
              tableFilters?.entity_ids?.length === 0 ||
              selectedEntity === -1
            ) {
              return;
            }

            // Update payload to retrieve action events
            setEntityActionEventsPayload({
              ...createPaginationPayload(state),
              ...tableFilters,
            });
          }
        }}
      />
    </U21Section>
  );
};
