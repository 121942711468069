import { useQuery } from '@tanstack/react-query';
import {
  AlertsSearchResponse,
  CasesSearchResponse,
  EntitiesSearchResult,
  EntitySearchResponse,
  EventsSearchResponse,
  EventsSearchResult,
  InstrumentsSearchResponse,
  InstrumentsSearchResult,
  RulesSearchResponse,
  SearchRequest,
  SearchResponse,
  SearchTypes,
  TeamsSearchResponse,
} from 'app/modules/search/models';
import { NAVIGATOR_QUERY_KEYS } from 'app/modules/navigator/queries/keys';
import { post } from 'app/shared/utils/fetchr';
import { selectSearchV2Enabled } from 'app/shared/featureFlags/selectors';
import { useSelector } from 'react-redux';
import { GenericSearchPayload } from 'app/modules/search/payload';
import {
  ActionEventSearchV2Response,
  ActionEventSource,
  BaseV2Hits,
  EntitySearchV2Response,
  EntitySource,
  InstrumentSearchV2Response,
  InstrumentSource,
  SearchV2Response,
  TransactionSearchV2Response,
  TransactionSource,
} from 'app/modules/search/response';
import { getDisplayName } from 'app/modules/search/helpers';

export const convertV2Result = (
  data: SearchV2Response,
  type?: SearchTypes,
): SearchResponse => {
  switch (type) {
    case SearchTypes.ENTITIES:
      return {
        data: (data.hits as BaseV2Hits<EntitySource>[]).reduce<
          EntitiesSearchResult[]
        >(
          (
            acc: EntitiesSearchResult[],
            { source }: BaseV2Hits<EntitySource>,
          ) => {
            const id = parseInt(source.unit21_id ?? '', 10);

            if (!isNaN(id)) {
              // This will filter out UNIT21_ID_NOT_FOUND as well

              acc.push({
                ...source,
                id,
                name_readable: getDisplayName(source),
              });
            }
            return acc;
          },
          [],
        ),
      };
    case SearchTypes.TRANSACTIONS:
    case SearchTypes.ACTION_EVENTS:
    case SearchTypes.INSTRUMENTS:
      return {
        data: (
          data.hits as BaseV2Hits<
            TransactionSource | ActionEventSource | InstrumentSource
          >[]
        ).reduce<(EventsSearchResult | InstrumentsSearchResult)[]>(
          (
            acc: (EventsSearchResult | InstrumentsSearchResult)[],
            {
              source,
            }: BaseV2Hits<
              TransactionSource | ActionEventSource | InstrumentSource
            >,
          ) => {
            const id = parseInt(source.unit21_id ?? '', 10);

            if (!isNaN(id)) {
              acc.push({
                ...source,
                id,
              });
            }
            return acc;
          },
          [],
        ),
      };
    default:
      return { data: [] };
  }
};

export const useGlobalSearch = (
  type: SearchTypes | undefined,
  payload: SearchRequest,
  newPayload?: GenericSearchPayload,
) => {
  const isSearchV2Enabled = useSelector(selectSearchV2Enabled);

  const v1Query = useQuery<SearchResponse>({
    queryFn: () => {
      switch (type) {
        case SearchTypes.ACTION_EVENTS:
          return post<EventsSearchResponse>('/action-events/search', payload);
        case SearchTypes.ALERTS:
          return post<AlertsSearchResponse>('/alerts/search', payload);
        case SearchTypes.CASES:
          return post<CasesSearchResponse>('/cases/search', payload);
        case SearchTypes.ENTITIES:
          return post<EntitySearchResponse>('/entities/search', payload);
        case SearchTypes.INSTRUMENTS:
          return post<InstrumentsSearchResponse>(
            '/instruments/search',
            payload,
          );
        case SearchTypes.RULES:
          return post<RulesSearchResponse>('/rules/search', payload);
        case SearchTypes.TEAMS:
          return post<TeamsSearchResponse>('/teams/search', payload);
        case SearchTypes.TRANSACTIONS:
          return post<EventsSearchResponse>('/events/search', payload);
        default:
          return Promise.resolve({ data: [] });
      }
    },
    queryKey: NAVIGATOR_QUERY_KEYS.search(type, payload),
    enabled: Boolean(
      !isSearchV2Enabled && type && payload.phrase && payload.phrase.length > 1,
    ),
    staleTime: 5 * 60 * 1000,
  });

  const v2Query = useQuery<SearchV2Response, Error, SearchResponse>({
    queryFn: () => {
      switch (type) {
        case SearchTypes.ENTITIES:
          return post<EntitySearchV2Response>(
            '/entities/search/v2',
            newPayload,
          );
        case SearchTypes.TRANSACTIONS:
          return post<TransactionSearchV2Response>(
            '/txn_events/search/v2',
            newPayload,
          );
        case SearchTypes.ACTION_EVENTS:
          return post<ActionEventSearchV2Response>(
            '/action_events/search/v2',
            newPayload,
          );
        case SearchTypes.INSTRUMENTS:
          return post<InstrumentSearchV2Response>(
            '/instruments/search/v2',
            newPayload,
          );
        default:
          return Promise.resolve({ hits: [], took: 0 });
      }
    },
    queryKey: NAVIGATOR_QUERY_KEYS.search(type, newPayload),
    enabled: Boolean(
      isSearchV2Enabled &&
        payload.type !== 'unit21_id' &&
        type &&
        newPayload &&
        newPayload.phrases &&
        (newPayload.phrases.length > 1 ||
          (newPayload.phrases.length === 1 &&
            newPayload.phrases[0].length > 1)),
    ),
    staleTime: 5 * 60 * 1000,
    select: (data) => convertV2Result(data, type),
  });

  return isSearchV2Enabled ? v2Query : v1Query;
};
