import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import { IconFlag } from '@u21/tabler-icons';
import { IconFlagX } from 'app/shared/components/Icons/IconFlagX';
import { U21Section, U21Alert, U21Spacer } from 'app/shared/u21-ui/components';
import { Histogram } from 'app/modules/histogram/components/Histogram';

// Models
import { GetAlertHistogramPayload } from 'app/modules/alerts/requests';

// Selectors
import {
  selectAlertHistogram,
  selectAlertHistogramLoading,
  selectAlertEntities,
  selectAlert,
} from 'app/modules/alerts/selectors';
import { selectDLEnabledForAlertHistogram } from 'app/shared/featureFlags/selectors';

// Actions
import { retrieveAlertHistogram } from 'app/modules/alerts/actions';
import { useGetHistogram } from 'app/modules/alerts/queries/useGetHistogram';

// Constants
import { INITIAL_HISTOGRAM_DATA } from 'app/modules/alerts/constants';
import { selectActivityFilters } from 'app/modules/alerts/sliceSelectors';

export const ActivityHistogram = () => {
  const dispatch = useDispatch();
  const { id: alertId } = useSelector(selectAlert);
  const legacyHistogramLoading = useSelector(selectAlertHistogramLoading);
  const legacyHistogram = useSelector(selectAlertHistogram);
  const hasDLHistogramEnabled = useSelector(selectDLEnabledForAlertHistogram);
  const { selectedEntity, showCurrentAlert } = useSelector(
    selectActivityFilters,
  );

  useEffect(() => {
    if (!hasDLHistogramEnabled) {
      dispatch(
        retrieveAlertHistogram(
          showCurrentAlert
            ? { alert_id: alertId }
            : { entity_id: selectedEntity },
        ),
      );
    }
  }, [
    alertId,
    dispatch,
    hasDLHistogramEnabled,
    selectedEntity,
    showCurrentAlert,
  ]);

  const entities = useSelector(selectAlertEntities);
  const selectedEntityRes = useMemo(
    () => entities.find(({ id: entityId }) => entityId === selectedEntity),
    [entities, selectedEntity],
  );
  const currentEntityName = selectedEntityRes?.name_readable;

  const histogramPayload: GetAlertHistogramPayload = useMemo(() => {
    if (!showCurrentAlert && selectedEntity) {
      const entityRes = entities.find(
        ({ id: entityId }) => entityId === selectedEntity,
      );
      if (entityRes?.external_id) {
        return {
          entity_external_id: entityRes.external_id,
        };
      }
    }
    return { alert_id: alertId };
  }, [alertId, showCurrentAlert, selectedEntity, entities]);

  const {
    data: histogramFromDL = INITIAL_HISTOGRAM_DATA,
    isLoading: histogramFromDLLoading,
  } = useGetHistogram(histogramPayload);
  const histogram = hasDLHistogramEnabled ? histogramFromDL : legacyHistogram;
  const histogramLoading = hasDLHistogramEnabled
    ? histogramFromDLLoading
    : legacyHistogramLoading;

  const showWarning = useMemo(() => {
    const { in: inTxns, out: outTxns } = legacyHistogram.histogram_data;
    const numOfTxns = inTxns.length + outTxns.length;
    return hasDLHistogramEnabled ? false : numOfTxns > 25;
  }, [legacyHistogram.histogram_data, hasDLHistogramEnabled]);

  return (
    <U21Section
      titleIcon={showCurrentAlert ? <IconFlag /> : <IconFlagX />}
      title={
        !showCurrentAlert && currentEntityName
          ? `${currentEntityName}'s Transactions`
          : 'Transactions'
      }
    >
      <U21Spacer>
        {showWarning && (
          <U21Alert severity="info">
            The Transaction Histogram below only displays the latest 25
            Transactions added to the Unit21 system.
          </U21Alert>
        )}
        <Histogram
          histogram={histogram}
          loading={histogramLoading}
          type="transactions"
        />
      </U21Spacer>
    </U21Section>
  );
};
