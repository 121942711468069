import { Filter } from 'app/modules/filters/models';

import { AlertPages } from 'app/modules/alerts/models';
import { ALERTS_SLICE_NAME } from 'app/modules/alerts/sliceAlerts';

import { createSelector } from 'reselect';
import { selectAlertQueueAlertFilters } from 'app/modules/queues/selectors';

export const selectAdminAlertFilters = (state: RootState) =>
  state[ALERTS_SLICE_NAME].adminAlertFilters;

export const selectMyAlertFilters = (state: RootState) =>
  state[ALERTS_SLICE_NAME].myAlertFilters;

export const selectQueuedAlertFilters = (state: RootState) =>
  state[ALERTS_SLICE_NAME].queuedAlertFilters;

export const selectAlertFilters = createSelector(
  [
    selectAdminAlertFilters,
    selectMyAlertFilters,
    selectQueuedAlertFilters,
    selectAlertQueueAlertFilters,
    (_, type: AlertPages) => type,
  ],
  (
    adminFilters,
    myAlertFilters,
    queuedAlertFilters,
    alertQueueAlertFilters,
    type,
  ): Filter[] => {
    switch (type) {
      case AlertPages.ADMIN:
        return adminFilters;
      case AlertPages.MY_ALERTS:
        return myAlertFilters;
      case AlertPages.QUEUED_ALERTS:
        return queuedAlertFilters;
      case AlertPages.QUEUE_DETAILS:
        return alertQueueAlertFilters;
      default:
        return [];
    }
  },
);

export const selectActivityFilters = (state: RootState) =>
  state[ALERTS_SLICE_NAME].activityFilters;

export const selectSelectedEntityActivityFilter = createSelector(
  selectActivityFilters,
  (activityFilters) => activityFilters.selectedEntity,
);

export const selectShowCurrentAlertActivityFilter = createSelector(
  selectActivityFilters,
  (activityFilters) => activityFilters.showCurrentAlert,
);
