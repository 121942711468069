import { SearchRequest, SearchTypes } from 'app/modules/search/models';
import { GenericSearchPayload } from 'app/modules/search/payload';

const BASE = ['navigator'];

export const NAVIGATOR_QUERY_KEYS = {
  getVersion: () => [...BASE, 'version'],
  search: (
    type?: SearchTypes,
    payload?: SearchRequest | GenericSearchPayload,
  ) => {
    if (type) {
      return payload
        ? [...BASE, 'search', type, payload]
        : [...BASE, 'search', type];
    }

    return [...BASE, 'search'];
  },
};
