import { get } from 'app/shared/utils/fetchr';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { FINCEN_SAR_KEYS } from 'app/modules/fincenSarNew/queries/keys';
import { useSelector, useDispatch } from 'react-redux';
import { selectHasReadSARsPermissions } from 'app/modules/session/selectors';
import {
  CachedSARFilingResponse,
  FullSARFilingResponse,
} from 'app/modules/fincenSarNew/response';
import { selectDLEnabledForSARDetail } from 'app/shared/featureFlags/selectors';
import { useEffect } from 'react';
import { setLoadingRetrieveSar } from 'app/modules/fincenSarNew/sliceFincenSar';

export const useGetFinCENSAR = (
  id: number,
  options: Pick<UseQueryOptions, 'refetchOnWindowFocus'> = {},
) => {
  const hasReadSARsPermission = useSelector(selectHasReadSARsPermissions);
  const useSARCacheEndpoint = useSelector(selectDLEnabledForSARDetail);
  const dispatch = useDispatch();
  const query = useQuery<CachedSARFilingResponse | FullSARFilingResponse>({
    ...options,
    enabled: hasReadSARsPermission,
    queryKey: FINCEN_SAR_KEYS.getFinCENSAR(id, useSARCacheEndpoint),
    queryFn: () =>
      useSARCacheEndpoint
        ? get<CachedSARFilingResponse>(`/sars/cached/${id}`)
        : get<FullSARFilingResponse>(`/sars/${id}`),
  });

  useEffect(() => {
    dispatch(setLoadingRetrieveSar(query.isLoading));
  }, [dispatch, query.isLoading]);

  return query;
};
